
//    const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';
import withRouter from 'react-router-dom/withRouter';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
import SvgViewer from './../customComponents/svgViewer';
import { Checkbox } from 'primereact/checkbox';
import Authentication from '../session';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { BreadCrumb } from 'primereact/breadcrumb';
//import {ModuleMenuItems} from './../../constants';
import './styles.scss';
import Table from '../table';
import TableDynamic from '../tableDynamic';
import LazyTable from '../lazyTable';
import { TieredMenu } from 'primereact/tieredmenu';
import { Toast } from 'primereact/toast';

import LoadingComponent from '../loadingComponent';
import RoleChange from './RoleChange';
import { PanelMenu } from 'primereact/panelmenu';
import { Dropdown } from 'primereact/dropdown';
import { I18Next } from '../../internationalization'
import { getAllLanguageArrayList } from '../../utile/formHelper';
import { userSignOut, setLanguage } from './../../store/actions';
import { baseUrlAdmin } from '../../store/apiConstants';
import { EncryptionKey } from '../../constants';
import CryptoJS from 'crypto-js';
import Service from '../../services';

// const items = [
//     { label: 'Categories' },
//     { label: 'Sports' },
//     { label: 'Football' },
//     { label: 'Countries' },
//     { label: 'Spain' },
//     { label: 'F.C. Barcelona' },
//     { label: 'Squad' },
//     { label: 'Lionel Messi' }
// ];

const home = { icon: 'pi pi-home' }





const getThemes = () => {
    if (localStorage.getItem('themes')) {
        return JSON.parse(localStorage.getItem('themes'));
    } else {
        return [
            {
                name: "Light Blue",
                value: null,
                isSelected: true
            },
            {
                name: "Pink",
                value: 'pink',
                isSelected: false
            },
            {
                name: "Green",
                value: 'green',
                isSelected: false
            },
            {
                name: "Yellow",
                value: 'yellow',
                isSelected: false
            },
        ]
    }
}

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            themes: getThemes(),
            isShowChangeRole: false,
            defaultLanguage: 'en',
            isLogged: false,
        }
        this.service = new Service();

    }
    logOut = () => {


        this.logOutAdmin() 
       this.props.userSignOut()
       
    }



    logOutAdmin = () => {
        
        
        this.setState({
            isLoading: true
        })
        
            const url = `${baseUrlAdmin}/teacherauth/logout`;
          

            if (this.props.userInfo) {

                console.log(this.props.userInfo,"userinfooooooo")
                debugger
                const encryptedUsername = CryptoJS.AES.encrypt(this.props.userInfo.username, EncryptionKey).toString();
    
            
                let  payload = {
                  username: encryptedUsername,
                };
              
            this.service.post(url, payload, true).then((data) => {


                if (data && data.res && data.res.status===true) {
                    console.log("logged")
                    this.setState({
                        //roles: data.res.data,
                        isLoading: false,
                        isLogged:true
                    }, () => {
                        
                        //window.location.reload();

                    });
                    // if(this.state.isLogged){
                    //     debugger
                    //     console.log(this.state.isLogged,"isLogged")
                    //     this.props.userSignOut()
                    //     this.setState({
                    //         isLogged: false
                    //     })
                    // }
                    

                } else {

                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to get the data', life: 3000 });
                }
    
    
    
    
            }).catch(e => {
    
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }
        }
        

    componentDidMount() {
        // if (!this.props.assignedModules) {
        //     this.props.getOrgData();
        // }
        const storedName = localStorage.getItem('defaultLanguage');
        if (storedName) {
            this.setState({
                defaultLanguage: storedName
            })
        }

       
    }


    getNavbarModules = (langType) => {
        
        
     
       
        let ModuleMenuItems=[
            {
                label: "Teachers Proffesional Training",
                isPermRequired: false,
                items: [

                    {
                           
                        label: 'Boards Master',
                        isPermRequired: true,
                        command: () => {
                            this.props.history.push('/boards')
                        },
                        isPermRequired: true,
                        uId: '5311cf61-a84f-433b-8edf-b61134f5acf3',
                        
                    },
                    {
                        label: 'Branch Master',
                        isPermRequired: true,
                        uId: "5311cf61-a84f-433b-8edf-b71134f5acf3",
                        command: () => {
                            this.props.history.push('/branch')
                        },

                    },
                     {
                        label: 'Curriculum Master',
                        isPermRequired: true,
                        uId: "aa4160b6-9d04-48d3-a090-da8cae2f5b06",
                        command: () => {
                            this.props.history.push('/curriculum')
                            },
                                   
                        },
                        {
                           
                            label: 'Content Master',
                            isPermRequired: true,
                            uId: "e2e589db-0ce1-4fd9-aba5-929b772a5507",
                            command: () => {
                                this.props.history.push('/content')
                            },
                            
                        },

                        
                        {
                            label: 'Course Management',
                             uId: "cea58a5e-1880-4fbd-957a-ba2140435d3e",
                            isPermRequired: true,
                            command: () => {
                                this.props.history.push('/course-management')
                            },
                            
                        },
                        // {
                        //  label: 'Assign Content',
                        //  isPermRequired: true,
                        //  uId: "86e09c71-cb58-4515-9eb5-7f0180abefff",
                        //     command: () => {
                        //         this.props.history.push('/assign-content')
                        //     },
                            
                        // },
                        {
                            label: 'Examination',
                            isPermRequired: true,
                            uId: '0a1f9cfd-9239-495d-bbb4-47c05233f5e1',
                            items: [{
                                label: 'Question Bank',
                                isPermRequired: true,
                                uId: "0a1f9cfd-9239-495d-bbb4-47c05233f5e1",
                                command: () => {
                                    this.props.history.push('/question-bank')
                                },
                            },
                            {
                                label: 'Assign Question',
                                isPermRequired: true,
                                uId: "0a1f9cfd-9239-495d-bbb4-47c05233f5e1",
                                command: () => {
                                    this.props.history.push('/assign-question')
                                },
                            },
                            {
                                label: 'Exams',
                                isPermRequired: true,
                                uId: "0a1f9cfd-9239-495d-bbb4-47c05233f5e1",
                                command: () => {
                                    this.props.history.push('/examination')
                                },
                                
                            }
                            ]
                        },
                        {
                            label: 'Admin Management',
                            isPermRequired: true,
                            uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510c",
                             command: () => {
                                this.props.history.push('/admin-manage')
                            },

                            // items: [
                            //     {
                            //         label: 'Admins',
                            //         isPermRequired: true,
                            //         uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510c",
                            //         command: () => {
                            //             this.props.history.push('/admin-manage')
                            //         },
                            //     },
                            //     {
                            //         label: 'BulkUpload', 
                            //         isPermRequired: true,
                            //         uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510c",
                            //         command: () => {
                            //             this.props.history.push('/admins-bulkupload')
                            //         },
                                   
                            //     }]
                        },
                        {
                            label: 'User Management',
                            isPermRequired: true,
                            uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510b",
                            command: () => {
                                this.props.history.push('/user-management')
                            },
                            // items: [{
                            //     label: 'Users',
                            //     isPermRequired: true,
                            //     uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510b",
                            //     command: () => {
                            //         this.props.history.push('/user-management')
                            //     },
                            // },
                            // {
                            //     label:'BulkUpload', 
                            //     isPermRequired: true,
                            //     uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510b",
                            //     command: () => {
                            //         this.props.history.push('/user-bulkupload')
                            //     },
                               
                            // }]
                        },
                        {
                            
                            label: 'Venue Management',
                            isPermRequired: true,
                            uId: "fe68159a-2fa0-40b2-9736-0e676eacb198",
                            command: () => {
                                this.props.history.push('/venue-management')
                            },
                        },
                        {
                            label: 'Trainer Management',
                            isPermRequired: true,
                            uId: "bb6a3d92-ec83-4ed3-a862-b0c070e571c8",
                            command: () => {
                                this.props.history.push('/trainer-management')
                            },
                        }, 
                      /*   {
                            
                            label: 'Settings',
                            isPermRequired: true,
                            uId: "fe68159a-2fa0-40b2-9736-0e676eacb198",
                            command: () => {
                                this.props.history.push('/settings')
                            },
                        }, */
                        
            
                ]
            }

        ]

        // if (this.props.userType == "trainer") {
        //     ModuleMenuItems = TrainerMenuItems
        // }

        let assignedM = [];


        if (this.props.userInfo && this.props.userInfo.isSuperAdmin) {
            return ModuleMenuItems;
        }


        if (this.props.assignedModules && this.props.assignedModules.length && this.props.permissionIds && this.props.permissionIds.length) {

            let assignedModules = this.props.assignedModules.map(m => m.id)
            assignedM = ModuleMenuItems.filter(item => {

                if (item.isPermRequired) {
                    // 
                    if (assignedModules.includes(item.uId) && this.props.permissionIds.includes(item.uId)) {
                        if (item.items) {
                            item.items = item.items.filter(subItem => {
                                if (subItem.isPermRequired) {
                                    //
                                    if (assignedModules.includes(subItem.uId)) {
                                        if (subItem.items && subItem.items.length) {
                                            subItem.items = subItem.items.filter(subItem2 => {
                                                if (subItem2.isPermRequired) {
                                                    if (this.props.permissionIds.includes(subItem2.uId)) {
                                                        return subItem2;
                                                    }
                                                } else {
                                                    return subItem2;
                                                }
                                            });
                                        }
                                        return subItem;
                                    }
                                } else {
                                    if (subItem.items && subItem.items.length) {
                                        subItem.items = subItem.items.filter(subItem2 => {
                                            if (subItem2.isPermRequired) {
                                                if (this.props.permissionIds.includes(subItem2.uId)) {
                                                    return subItem2;
                                                }
                                            } else {
                                                return subItem2;
                                            }
                                        });

                                    }
                                    return subItem;
                                }
                            });
                        }

                        if (item && item.items && item.items.length) {
                            return item
                        }
                    }
                } else {
                    if (item.items) {
                        item.items = item.items.filter(subItem => {
                            if (subItem.isPermRequired) {
                                if (assignedModules.includes(subItem.uId) && this.props.permissionIds.includes(subItem.uId)) {
                                    if (subItem.items && subItem.items.length) {
                                        subItem.items = subItem.items.filter(subItem2 => {
                                            if (subItem2.isPermRequired) {
                                                if (this.props.permissionIds.includes(subItem2.uId)) {
                                                    return subItem2;
                                                }
                                            } else {
                                                return subItem2;
                                            }
                                        });
                                    }
                                    return subItem;
                                }
                            } else {
                                if (subItem.items && subItem.items.length) {
                                    subItem.items = subItem.items.filter(subItem2 => {
                                        if (subItem2.isPermRequired) {
                                            if (this.props.permissionIds.includes(subItem2.uId)) {
                                                return subItem2;
                                            }
                                        } else {
                                            return subItem2;
                                        }
                                    });

                                }
                                return subItem;
                            }
                        });
                    } else {
                        return item;
                    }
                    if (item && item.items && item.items.length) {
                        return item
                    }
                }
            });
        }


        assignedM = assignedM.filter(i => {
            if (i && i.items) {
                if (i.items.length) {
                    i.items = i.items.filter(j => {
                        if (j && j.items) {
                            if (j.items.length) {
                                j.items = j.items.filter(k => {
                                    if (k && k.items) {
                                        if (k.items.length) {
                                            return k;
                                        }
                                    } else {
                                        if (k.command) {
                                            return k;
                                        }

                                    }
                                })
                                if (j.items.length) {
                                    return j;
                                }

                            }
                        } else {
                            if (j.command) {
                                return j;
                            }

                        }
                    })

                    if (i.items.length) {
                        return i;
                    }
                }
            } else {
                if (i.command) {
                    return i;
                }

            }
        })
        return assignedM;
    }

    changeTheme = (selectedTheme) => {

        const themes = this.state.themes.map((theme) => {
            if (theme.value == selectedTheme.value) {
                theme.isSelected = true;
            } else {
                theme.isSelected = false;

            }
            return theme;
        })
        this.setState({
            themes
        });



        localStorage.setItem('themes', JSON.stringify(themes));
        localStorage.setItem('data-theme', selectedTheme.value);
        document.documentElement.setAttribute('data-theme', localStorage.getItem('data-theme'));
        //  window.location.reload();
    }


    getThemes = () => {
        const { themes } = this.state;
        return <ul className='ma-p10'>{themes.map(theme => {
            return <li key={theme.name} className='ma-m10' >
                <RadioButton className="radio-inline" value={theme.value} inputId={theme.value} name="theme"
                    onChange={() => {
                        this.changeTheme(theme)
                    }}
                    checked={theme.isSelected}
                />
                <label className='ma-label-s1' htmlFor={theme.value}>{theme.name}</label>
            </li>
        })}  </ul>


    }
    showRoleChange = () => {
        this.setState({
            isShowChangeRole: true
        })
    }

    setDefaultLanguage = (langeVal) => {

        localStorage.setItem('defaultLanguage', langeVal);
        this.props.setLanguage(langeVal)

        this.setState({
            defaultLanguage: langeVal
        })

    }
    render() {

        const items = [
            {
                label: 'Theme',
                icon: 'pi pi-slack',
                items: [
                    {
                        template: <>{this.getThemes()}</>
                    }
                ]
            },

            {
                separator: true
            },
            {
                label: 'Log Out',
                icon: 'pi pi-fw pi-power-off',
                command: () => { this.logOut() }
            }
        ];

        if (this.props.userInfo && this.props.userInfo && this.props.userInfo.canChangeRole) {

            items.splice(1, 0, {
                label: 'Change Role',
                icon: 'pi pi-user',
                command: () => { this.showRoleChange() }
            })

        }

        const { logoRectangle } = this.props;
        let defaultLang = getAllLanguageArrayList?.find((vl) => vl?.code === this?.state?.defaultLanguage)
        return (
            <div className='ma-navbar'>

                <div className="p-grid ma-menubar-w">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {/* {logoRectangle?.storageLocation && <img src={`${logoRectangle?.storageLocation ? logoRectangle?.storageLocation : ""}`} className='ma-logo' />} */}
                        <img src={`/images/TPDA.png`} className='ma-logo' />
                    </div>
                    <div className="p-col-12 p-md-11">
                        <div className="p-grid ma-no-p ma-menubar">
                            <div className="p-col-12 p-md-10 ma-no-p">
                                <Menubar model={this.getNavbarModules(this?.state?.defaultLanguage)}
                                    className="m-menu-bar "
                                />
                            </div>
                            {/* <div className="p-col-12 p-md-2">
                                <Dropdown
                                    value={defaultLang}
                                    onChange={(e) => this.setDefaultLanguage(e.value.code)}
                                    options={getAllLanguageArrayList}
                                    optionLabel="name"
                                    className="w-full md:w-14rem"
                                    placeholder="Select a language" style={{ float: "right" }} />
                            </div> */}
                            <div className="p-col-12 p-md-2 ma-right ">
                                <div style={{ width: '40px', float: 'right' }}>
                                    <img src={'./images/faculty.jpg'} onClick={(event) => this.menu.toggle(event)} style={{ height: '34px', borderRadius: '50%' }} />
                                </div>
                                <div style={{ marginRight: '50px' }}>
                                    <p style={{ textAlign: 'right' }} className='ma-profile'>
                                        {/* <i className='pi pi-angle-down'></i> */}
                                        {this.props.userName}</p>
                                </div>
                                {/* <Dropdown 
                                    value={defaultLang} 
                                    onChange={(e) => this.setDefaultLanguage(e.value.code)} 
                                    options={getAllLanguageArrayList} 
                                    optionLabel="name" 
                                    className="w-full md:w-14rem" 
                                    placeholder="Select a language" /> */}
                            </div>
                        </div>
                        {/* <Menu
                            className='log-out-menu'
                            model={[
                                {
                                    template: (<div className='log-out'>
                                        <ul>
                                            <li onClick={this.props.userSignOut} > <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/logout.svg' width="16" height="16" /><span>Log out</span></li>
                                            <li onClick={this.props.userSignOut} > <i className='pi pi-slack' /><span>Theme</span></li>
                                        </ul>
                                    </div>)
                                }]
                            }
                            popup
                            ref={el => this.menu = el} /> */}
                        <TieredMenu model={items} popup ref={el => this.menu = el} />
                        {/* <Button label="Log Out" icon="pi pi-power-off" onClick={this.logOut} /> */}
                        
                    </div>
                  

                    {
                        this.props.isLoading && <LoadingComponent />
                    }

                    {this.state.isShowChangeRole && <RoleChange
                        onCloseDialog={() => { this.setState({ isShowChangeRole: false }) }}
                        _id={this.props.userInfo && this.props.userInfo._id}
                        currentRole={this.props.userInfo && this.props.userInfo.roles && this.props.userInfo.roles[0]}
                    />}


                </div>
                {/* <div>
                    <PanelMenu model={this.getNavbarModules()} style={{ width: '300px' }} />
                </div> */}

                <div className='ma-main-body'>

                    {this.props.children}
                </div>
                <div className='ma-footer'>
                    <p className='' >Powered by  ©MACADEMY</p>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    userName: localStorage.getItem('name') || 'Admin',
    isAdmin: localStorage.getItem('isAdmin'),
    picture: './360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg',
    assignedModules: state.orgData.assignedModules,
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    isLoading: state.orgData.isLoading,
    isSuperOrg: state.orgData.isSuper,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds ? state.currentUserPerms.permissionIds : [],
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
    defaultLanguage: state.languageReducer.language,
    userType: localStorage.getItem('userType'),
});

const mapDispatchToProps = (dispatch) => ({
    setLanguage: (defaultLanguage) => dispatch(setLanguage(defaultLanguage)),
    userSignOut: () => dispatch(userSignOut()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Authentication(withRouter(Navbar)));
